<script>

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
      const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesLoadingOpen = ref(false)

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesLoadingOpen
        };
    },
    page: {
    title: "users",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Utilisateurs",
      items: [
        {
          text: "Définition",
        },
        {
          text: "Utilisateurs",
          active: true,
        },
      ],
      page: 1,
      perPage: 25,
      users: [],
      users_search: [],
      search: '',
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('users'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        this.users_search = data
        this.users = data
        this.doesLoadingOpen = false
      })
    },
    filter_user() {
      this.users = this.users_search
      if (this.search != "") {
        this.users = this.users.filter(user => (
          user.last_name?.toLowerCase() == this.search.toLowerCase() || 
          user.first_name?.toLowerCase() == this.search.toLowerCase() || 
          user.email.toLowerCase() == this.search.toLowerCase()
        )) 
      }
    },
    show(index) {
        return (index < this.page * this.perPage && (index + 1) > (this.page - 1) * this.perPage)
    },
    next() {
        if ((this.page * this.perPage) < this.users.length) {
            this.page++
        }
    },
    previous() {
        if (this.page >= 2) {
            this.page--
        }
    },
  },
  mounted() {
    this.loadList()
    this.doesLoadingOpen = false
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row" v-show="status != 403">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des Utilisateurs</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>Utilisateurs</code>.
            </p>

            <div class="row mb-4">
              <div class="col-md-4">
                <input type="search" class="form-control" v-model="search" v-on:input="filter_user()" placeholder="Rechercher une personne">
              </div>
              <div class="col-2 ms-auto">
                <select v-model="perPage" class="form-control text-center">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>

              <div class="ms-2 col-2 ps-5 hstack">
                <i class="bx bx-left-arrow-alt p-2 fs-5" type="button" @click="previous"></i>
                
                <i class="bx bx-right-arrow-alt fs-5 p-2 ms-3" type="button" @click="next"></i>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr class="bg-light">
                    <th>#</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Sexe</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                    <th class="text-center">Statut</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in users" v-show="show(index)" :key="user.id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ user.last_name }}</td>
                    <td>{{ user.first_name }}</td>
                    <td>{{ user.gender == true ? "Masculin" : "Féminin" }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td class="text-center">
                      <button class="badge badge-pill badge-soft-success border-0 py-1 w-100" v-if="user.status == 'ACTIF'">ACTIF</button>
                      <button class="badge badge-pill badge-soft-danger border-0 py-1 w-100" v-else>{{ user.status }}</button>
                    </td>
                    <td class="text-center">
                      <router-link :to="'/users/'+user.id">
                        <b-button class="btn-sm ms-2 rounded-pill px-2" variant="primary">
                            Visiter le compte
                        </b-button>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>

    <div v-show="status === 403">
      <h3 class="text-red-">Vous n'avez pas accès à cette page</h3>
    </div>


    <!-- LOADING MODAL -->
    <b-modal v-model="doesLoadingOpen" id="modal-center" centered size="md" title="Chargement..."
      title-class="font-18" hide-footer>
      <div class="text-center">
        <div class="spinner-border text-primary-" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>


  </Layout>
</template>
